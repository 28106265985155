<template>
  <div>
      <v-flex xs12 md6>
        <v-combobox
          v-model="countrySelected"
          :items="countries"
          label="Your country"
          item-text="name"
          clearable
          outlined
          dense
          :menu-props="comboboxProps"
        ></v-combobox>
      </v-flex>
      

      <v-simple-table v-if="countrySelected">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" id=countryColumn>
                Country
              </th>
              <th class="text-left">
                ISO
              </th>
              <th class="text-left">
                Situation
              </th>
              <th class="text-left">
                Comments
              </th>
              <th class="text-left">
                Update date
              </th>

            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in countries"
              :key="item.name"
              @click="details(item)"
              class="tableRow"
            >
              <td>{{ item.name }}</td>
              <td>
                <country-flag :country='item.code' size='small'/>
              </td>
              <td>
                {{ item.situation }}
              </td>
              <td>
                {{ item.comments }}
              </td>
              <td>
                {{ item.lastUpdate }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <span v-else class="choseCountry">
        Choose your country
      </span>

  </div>
</template>

<script>
  import en_countries from "./countries"
  import {sync} from 'vuex-pathify'
  import axios from "axios"

  export default {
    data() {
      return {
        countries: en_countries,
        comboboxProps: {
          maxHeight: 180
        }
      }
    },
    computed: {
      countrySelected: sync("countrySelected"),
    },
    methods: {
      async details(endCountry) {
        this.$dialogDetails()
      }      
    },
    created() {
      axios.get("https://restcountries.eu/rest/v2/all")
        .then(result => console.log(result.data))
    }
  }
</script>

<style lang="scss" scoped>
  #countryColumn {
    width: 20vw;
  }

  .tableRow {
    cursor: pointer;
  }

  .choseCountry {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
</style>