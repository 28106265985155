<template>
  <mn />
</template>

<script>
  import main from '../components/main'

  export default {
    name: 'Home',

    components: {
      mn: main
    },
  }
</script>
