<template>
  <v-dialog
    v-model="dialog"
    persistent :overlay="false"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card class="pb-5">
      <v-card-title primary-title>
        Details
        <v-spacer></v-spacer>
        <v-btn @click="$close(false)" icon text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
        <v-row class="ma-0 justify-center">
          <country-flag :country='startCountry.code' size='big'/>
          <v-icon large>mdi-redo</v-icon>
          <country-flag :country='endCountry.code' size='big'/>
        </v-row>
        <v-row class="ma-0 justify-space-around">
          <h4>
            {{startCountry.name}}
          </h4>
          <h4>
            {{endCountry.name}}
          </h4>
        </v-row>

        <v-row class="ma-1 justify-center">
            <v-chip color="success" dark>No Visa</v-chip>
        </v-row>
        <v-row class="ma-1 justify-center">
            <v-chip color="error" dark>PCR Test</v-chip>
        </v-row>
        <v-row class="ma-1 justify-center">
            <a target="_blank" href="https://www.visittheusa.de/covid-19-reisehinweise-fur-die-usa">Official information</a>
        </v-row>

        
        
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      startCountry: {
        type: Object,
        default() {
          return {
            code: "gb",
            name: "United Kingdom"
            }
        }
      },
      endCountry: {
        type: Object,
        default() {
          return {
            code: "us",
            name: "United states"
            }
        }
      }
    },
    data() {
      return {
        dialog: true
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>