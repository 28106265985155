<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      src="@/assets/logo.jpg"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.9)"
        ></v-img>
      </template>
      <v-icon class="mr-1">
        mdi-airport
      </v-icon>
      <v-toolbar-title>Abroad radar</v-toolbar-title>

      <v-spacer></v-spacer>
      <language />

    </v-app-bar>

    <v-main>
      <v-container grid-list-xs>
        <router-view/>  
      </v-container>
    </v-main>

    <dialogs-wrapper ></dialogs-wrapper>

  </v-app>
</template>

<script>
import language from "./components/lib/language.vue"

export default {
  components: {language},

  data: () => ({
    //
  }),
};
</script>
