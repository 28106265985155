import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import CountryFlag from 'vue-country-flag'
import * as ModalDialogs from 'vue-modal-dialogs'


import details from './components/details'
const dialogDetails = ModalDialogs.create({component: details, props : ['startCountry']})
Vue.prototype.$dialogDetails = dialogDetails


Vue.use(ModalDialogs) 

Vue.component('country-flag', CountryFlag)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
